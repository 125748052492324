export function generateTooltipMarkup(inputName, tooltipMessage) {
	// parent div
	const parentDiv = document.createElement('div');
	parentDiv.className = 'form-group__tooltip';

	// child icon element
	const i = document.createElement('i');
	i.className = 'form-group__tooltip-icon icon-fill-question';
	i.setAttribute('data-bind', 'tooltip-icon');
	i.setAttribute('tooltip-name', inputName);

	// child div
	const div = document.createElement('div');
	div.classList.add('form-group__tooltip-message');
	div.innerHTML = tooltipMessage;

	// append children to parent
	parentDiv.appendChild(i);
	i.insertAdjacentElement('afterend', div);

	return parentDiv;
}
