/**
 * This function tracks mouse movements for accessibility purposes
 */
export function trackMouseMovement() {
	window.modForm.opts.form.addEventListener('mousemove', function() {
		if (!window.modForm.opts.form.classList.contains('form--disable-step-focus')) {
			window.modForm.opts.form.classList.add('form--disable-step-focus');
		}
	});

	window.modForm.opts.form.querySelectorAll('fieldset label, input[type="radio"]').forEach(function(element) {
		element.addEventListener('mouseup', function() {
			window.modForm.radioButtonClickedByMouse = true;
		});
	});
}
