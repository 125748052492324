import { loadLeadTokenScriptsTriggers } from '../../helpers';
/**
 * Init lead token scripts (TrustedForm, Journaya) triggers via radio and CTA clicks
 */
export function initLeadTokenScriptsTriggers() {
	function triggerLoad(e) {
		e.stopPropagation();
		loadLeadTokenScriptsTriggers();
	}

	window.modForm.opts.form.querySelectorAll('.btn-primary, .btn--primary, input[type="radio"], input[type="text"]').forEach(function(element) {
		if (!element) {
			return;
		}

		element.addEventListener('click', triggerLoad, false);
		element.addEventListener('keypress', triggerLoad, false);
	});
}
