/**
 * Replacing recaptcha & g-recaptcha-response params with recaptchaCompleted flag
 * @param {Object} formData - Object of form data
 * @returns {Object} Processed formData
 */
export function swapRecaptchaTokenWithFlag(formData) {
	if (!formData) {
		return;
	}

	const hasRecaptchaToken = 'recaptcha' in formData && formData.recaptcha !== '';
	const hasGreCaptchaResponseToken = 'g-recaptcha-response' in formData && formData['g-recaptcha-response'] !== '';

	if (hasRecaptchaToken || hasGreCaptchaResponseToken) {
		formData.recaptchaCompleted = true;
		delete formData.recaptcha;
		delete formData['g-recaptcha-response'];
	}

	return formData;
}
