import { transformQuadlink } from '../../helpers';
/**
 * If quadlink wasn't transformed, retry via radio and CTA clicks
 */
export function initQuadTransformRetry() {
	window.modForm.opts.form.querySelectorAll('.btn-primary, input[type="radio"]').forEach(function(element) {
		if (!element) {
			return;
		}

		element.addEventListener('click', function(e) {
			if (window.modForm.opts.quadLinkHasBeenTransformed === false && Object.keys(window.modForm.opts.quadLinkParams).length === 0) {
				e.stopPropagation();
				transformQuadlink();
				window.modUtils.heap('track', ['Quad Transform Retry']);
			}
		});
	});
}
