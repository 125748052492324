/**
 * Handles click of zip-control to change zip code later in the flow
 */
export function zipControlButtonClick() {
	const zipControlContent = document.querySelector('.zip-control-content'),
		zipControlContainer = document.querySelector('.zip-control'),
		showZipBesideField = document.querySelector('.show-zip-beside-field'),
		zipControlContentInput = document.querySelector('.zip-control-content .form-input');

	window.modForm.zipControlButton.addEventListener('click', (e) => {
		e.preventDefault();
		zipControlContent.style.display = 'block';
		zipControlContainer.style.display = 'none';
		if (zipControlContentInput) {
			zipControlContentInput.focus();
		}
		if (showZipBesideField) {
			showZipBesideField.classList.add('col-sm-8');
		}
	});
}
