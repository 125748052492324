import {
	showStepWithFirstError,
	focusOnFirstErrorOnCurrentStep,
	markFieldAsInvalid
} from '../../helpers';
/**
 * Handle API errors
 * @param {Object} response - API response
 */
export function processAPIResponseErrors(response) {
	const fieldsToValidate = {
		phone: 'homePhone',
		email: 'email',
		first: 'firstName',
		last: 'lastName',
		address: 'address',
		zip: 'zip'
	};

	if (window.modForm.opts.energySage) {
		if (response.errors && Object.keys(response.errors).length) {
			Object.keys(response.errors).forEach(function(errorText) {
				Object.keys(fieldsToValidate).forEach(function(fieldName) {
					if (errorText.indexOf(fieldsToValidate[fieldName]) > -1) {
						markFieldAsInvalid(
							window.modForm.opts.form.querySelector(
								'input[name="' + fieldsToValidate[fieldName] + '"]'
							)
						);
						window.modUtils.heap('track', [
							'Validation API Error | ' + fieldsToValidate[fieldName],
							{}
						]);
					}
				});
			});
		}
	} else {
		response.invalidFields.forEach(function(name) {
			markFieldAsInvalid(window.modForm.opts.form.querySelector('input[name="' + name + '"]'));
			window.modUtils.heap('track', ['Validation API Error | ' + name, {}]);
		});
	}

	showStepWithFirstError();
	focusOnFirstErrorOnCurrentStep();
}
