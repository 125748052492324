/* 
 * This function adds user's geolocation data to the lead, so that it will be seen in lead analyzer.
 * This is part of WEBP-1379 to assess assess and measure the accuracy and performance of the Geolookup endpoint.
 * 
 * @param {Object} formData - form data
 */
export function addGeolocationData(formData) {
	const HSGeolookupData = window.currentLocationFromHSGeolookup || {};
	formData.HSGeolookupData = JSON.stringify(HSGeolookupData);

	return formData;
}
