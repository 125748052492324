import { showStep } from '../../../helpers';
/**
 * Show first step with error
 */
export function showStepWithFirstError() {
	const $errors = window.modForm.opts.form.querySelectorAll('.' + window.modForm.opts.fieldErrorCls);

	if ($errors.length) {
		const $firstStepWithError = $errors[0].parentElement.closest(window.modForm.opts.stepsSelector),
			firstStepID = $firstStepWithError.getAttribute('id'),
			firstErrorName = $errors[0].querySelector('input').getAttribute('name');

		if ($firstStepWithError) {
			showStep(firstStepID);
			window.modUtils.heap('track', [
				'Step Shown with Validation API Error | ' + firstStepID + ' ' + firstErrorName,
				{}
			]);
		}
	}
}
