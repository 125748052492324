import { prePopFormWithEddie } from '../../helpers';

export const getEddieData = function() {
	const currentParams = window.modUtils.getUrlParamsToObject();
	const { eddie } = currentParams;
	if (!eddie) {
		return false;
	}

	const apiEnv = `https://${window.modUtils.getEnv() === 'production' ? 'hs.leadpost.net/' : 'hsleadpost1.quinstage.com/'}`;
	const url = `${apiEnv}api/get/hseddie?quid=${eddie}`;

	const requestHeaders = new Headers();

	const requestOptions = {
		method: 'POST',
		headers: requestHeaders,
		redirect: 'follow',
		mode: 'cors'
	};

	fetch(url, requestOptions)
		.then(response => response.json())
		.then(jsonResponse => prePopFormWithEddie(jsonResponse))
		.catch(error => console.log('Something went wrong!', error));
};
