import {
	getCurrentStep,
	processContractorFormSubmitSuccess,
	processAPIResponseErrors,
	switchFormLoadingState,
	preProcessPayload
} from '../../helpers';
/**
 * Submit form data to the new API contractors endpoint
 * @param {Object} formData - form data
 * @param {Function} successCallback - success callback
 * @param {Function} errorCallback - error callback
 */
export function submitContractorFormDataToApi(formData, successCallback, errorCallback) {
	const currentStep = Array.from(window.modForm.opts.steps).indexOf(getCurrentStep()),
		typeRequest = currentStep === 0 ? 'POST' : 'PATCH';
	let apiUrl, authorization, contractorId;

	// Submit type and other info varies depending on the step
	if (currentStep === 0) {
		apiUrl = window.modUtils.getApiDomain() + '/v1/contractors';
		authorization = false;
	} else {
		const contractorIdValue = window.modForm.opts.form.querySelector('[name="contractorId"]').value,
			tokenValue = window.modForm.opts.form.querySelector('[name="token"]').value;

		apiUrl = window.modUtils.getApiDomain() + '/v1/contractors/' + contractorIdValue;
		authorization = 'Bearer ' + tokenValue;
		contractorId = contractorIdValue;
	}

	if (window.modForm.isSubmitting === false) {
		window.modForm.isSubmitting = true;

		let response = {};
		const request = new XMLHttpRequest();
		request.open(typeRequest, apiUrl);
		request.setRequestHeader('Authorization', authorization);
		request.setRequestHeader('Accept', 'application/json');
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

		if (window.modUtils.getEnv() !== 'production') {
			request.setRequestHeader('Access-Control-Allow-Origin', window.location.origin);
			request.setRequestHeader('Access-Control-Allow-Methods', typeRequest);
		}

		request.send(new URLSearchParams(preProcessPayload(window.modForm.opts.submitData)).toString());

		request.addEventListener('load', function() {
			const xhr = this;
			if (this.response) {
				response = { ...response, ...JSON.parse(this.response) };
			} else {
				response.data = { contractorId: contractorId };
			}

			if (this.readyState === 4 && (this.status >= 200 && this.status <= 299)) {
				if ('function' === typeof successCallback) {
					successCallback(response);
				}
				// We clean temporal object
				window.modForm.opts.submitData = {};

				processContractorFormSubmitSuccess(response, currentStep, xhr);
			} else {
				// If there was an error, we first look for any error callback first
				if (typeof errorCallback === 'function') {
					errorCallback(xhr, this.status, response);
				} else if (typeof window.modForm.opts.formSubmitErrorCallback === 'function') {
					window.modForm.opts.formSubmitErrorCallback(response);
				} else {
					processAPIResponseErrors(response);
				}
			}
		});

		request.addEventListener('loadend', function() {
			window.modForm.isSubmitting = false;
			switchFormLoadingState(false);
		});

		request.addEventListener('error', function() {
			console.error('Something went wrong!');
		});
	}
}
