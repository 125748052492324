/**
 * Allow only numbers in a field (e.g. zip field)
 * @param {Object} $fields - object of fields
 * @returns {boolean} - false if no numeric fields defined
 */
export function initNumericOnlyFields($fields) {
	const allowedKeys = ['Control', 'Tab', 'ArrowLeft', 'ArrowDown', 'ArrowRight', 'ArrowUp', 'Backspace', 'Enter', 'Delete'];

	if (!$fields.length) {
		return false;
	}

	$fields.forEach(function(field) {
		field.addEventListener('keydown', function(e) {
			// Allow digits, arrows, enter, backspace, tab, etc
			if (Number.isNaN(parseInt(e.key)) && !allowedKeys.includes(e.key) && !e.metaKey) {
				e.preventDefault();
			}
		});
	});
}
