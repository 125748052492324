import { appendFormInput, getTcpaElementContent } from '../../../helpers';
/**
 * Add predefined data to the form
 */
export function addRequiredFieldsToForm() {
	const requiredFields = {
		session: '',
		sessionToken: '',
		usid: '',
		landingPage: window.modForm.opts.landingPageNoQuery,
		tcpa: 1,
		tcpaText: window.modForm.opts.tcpaCopy && window.modForm.opts.tcpaCopy.length ? Array.from(window.modForm.opts.tcpaCopy).map(tcpa => getTcpaElementContent(tcpa).trim().replace(/(<([^>]+)>)/ig, '').replace(/\n/g, '')) : ''
	};

	Object.keys(requiredFields).forEach((key) => {
		appendFormInput(key, requiredFields[key]);
	});
}
