export function getTradeFromUrl() {
	const trades = [
			'solar',
			'roofing',
			'windows',
			'hvac',
			'siding',
			'gutters',
			'bathrooms',
			'kitchen-remodeling',
			'cabinets',
			'home-security',
			'home-warranty',
			'medical-alerts',
			'stair-lifts',
			'walk-in-tubs',
			'hot-tubs',
			'flooring',
			'tree-services'
		],
		queryParamString = location.search.slice(1) !== '' ? location.search.slice(1) : '',
		params = queryParamString.toLowerCase(),
		filteredTrades = trades.filter(function(trade) {
			return params.includes(trade);
		});

	return filteredTrades[0];
}
