/**
 * Get Recaptcha token and uppend it to the form data
 * @param {Object} formData - form data
 * @param {Function} cb - callback
 */
export function appendRecaptchaToken(formData, cb) {
	if (!window.grecaptcha) {
		return;
	}

	window.grecaptcha.execute(window.__recaptchaKey, {
		action: 'formSubmitted'
	}).then(
		function(token) {
			// only append the recCaptcha token if truthy
			if (token && formData.compliance) {
				formData.compliance.recaptchaToken = token;
			}

			// regardless of outcome, invoke the formSubmit callback and pass
			// in the formatted form data
			cb(formData);
		},
		function(e) {
			cb(formData);
			console.error(e);
		}
	);
}
