import { getDefaultQuadByDomain } from '../../helpers';
/**
 * Get default quad link based on service
 * @param {String} service - Landing Page service (vertical)
 * @returns {String} Quad Link
 */
export function getDefaultQuad(service) {
	let c, s, f, finalLink = '';
	const valuesByDomain = getDefaultQuadByDomain();

	// allows us to pass in a custom object to create the default quadlink
	if (typeof service === 'object' && service !== null) {
		({ c, s, f } = service);
	} else if (valuesByDomain) {
		c = typeof valuesByDomain.c === 'object' ? valuesByDomain.c[service] : valuesByDomain.c;
		({ s, f } = valuesByDomain);
	}

	finalLink = `http://o1.qnsr.com/cgi/r?;n=203;c=${c};s=${s};x=7936;f=${f};u=j;z=TIMESTAMP;&default=yes;`;

	return finalLink;
}
