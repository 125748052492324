/**
 * This function maps the value shown on the frontend vs what's expected in the backend
 * @param {String} trade - trade
 * @returns {String} - transformed trade, if available
 */
export function mapProjectValue(trade) {
	const projectMap = {
		'Walk In Tubs': 'Bathroom',
		'Hot Tubs': 'Additions'
	};

	return projectMap[trade] || trade;
}
