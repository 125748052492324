import {showFallbackForDuplicateSubmission, getHeapSessionIdCookieData} from '../../../helpers';
/**
 * Check If Cookie Exits with Heap Session ID
 * @returns {boolean|undefined} - Returns false if a duplicate submission is detected and blocked, otherwise does not return anything.
 */
export function blockDuplicateSubmissionSameHeapSessionId() {
	const { heapSessionSubmitCookie, heapSessionId, initialTimestamp, expireInOneHour } = getHeapSessionIdCookieData();
	let { resultsPage } = getHeapSessionIdCookieData();

	if (heapSessionSubmitCookie !== null) {
		if (window.modForm.getTime > expireInOneHour) {
			// duplicate session ID, BUT submission was over an hour ago — continue with normal submission process
			return;
		} else {
			// duplicate session ID & trying to submit again within the last hour, redirect to original results page + log heap event
			window.modUtils.heap('track', ['Duplicate Form Submit With Same Heap Session ID | ', {
				resultsPage: resultsPage
			}]);

			if (resultsPage.indexOf(window.modForm.opts.suppressConversionPixelParam) <= -1 && !window.modForm.opts.isFrontdoorExclusiveMatch) {
				// suppress conversion pixels in HRC link & fire them from the page instead
				resultsPage += (resultsPage.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.suppressConversionPixelParam;
			}

			const duplicateSubmitFireCnvrsnPxlCookie = window.modUtils.getCookie('duplicateSubmitFireCnvrsnPxl');
			if (!duplicateSubmitFireCnvrsnPxlCookie) {
				if (resultsPage.indexOf('ThankYou') > -1) {
					window.location.href += '&ThankYou';

					// fire conversion pixels in case user does not redirect to HRC
					if (typeof dataLayer !== 'undefined') {
						window.dataLayer.push({
							event: 'Lead Conversion Failed Redirects'
						});
					}
				}
			}

			// updating heap session cookie to have updated resultsPage URL with suppression of conversion pixels
			window.modUtils.setCookie(
				heapSessionId,
				JSON.stringify({ initialTimestamp: initialTimestamp, resultsPage: resultsPage })
			);
			window.modUtils.setCookie('duplicateSubmitFireCnvrsnPxl', true);

			window.location.href = resultsPage;
			showFallbackForDuplicateSubmission(resultsPage);
			window.modForm.preventDuplicateSubmission = true;

			return false;
		}
	}
}
