import { showStep } from '../../../helpers';
/**
 * Init resubmit functionality when "Other" is selected as a primary project option
 */
export function initResubmitBtn() {
	const $resubmitBtn = window.modForm.opts.form.querySelector('[data-bind="resubmit"]'),
		$primaryProjectClass = window.modForm.opts.form.querySelector('select[name="primaryProjectClass"]');

	$resubmitBtn.addEventListener('click', function(e) {
		e.preventDefault();

		// Switch off TY state
		window.modForm.opts.form.classList.remove(window.modForm.opts.formTyCls);

		if ($primaryProjectClass) {
			// Reset trade selector
			$primaryProjectClass.value = '';
		}

		// Switch to 1st step
		const whichStep = window.modForm.opts.steps[0].id;
		showStep(whichStep);
	});
}
