/**
 * This function utilizes the HS Geolookup endpoint (WEBP-1379) to retrieve the user's geolocation.
 * This function is to assess and measure the accuracy and performance of the Geolookup endpoint.
 * 
 * Note: This function may be removed after the completion of the analysis and performance evaluation.
 */

function fetchGeolocation() {
	const apiDomain = `https://${window.modUtils.getEnv() === 'production' ? 'hs.leadpost.net/' : 'hsleadpost1.quinstage.com/'}`;
	const apiUrl = apiDomain + 'api/IP2GEO/get';
	fetch(apiUrl)
		.then(response => response.json())
		.then(data => {
			data = {
				'country': data.countryName,
				'city': data.city,
				'state': data.stateName,
				'statecode': data.stateCode,
				'zipcode': data.zip
			};
			window.currentLocationFromHSGeolookup = data;
		})
		.catch(error => {
			window.currentLocationFromHSGeolookup = {error: error};
		});
}

export function getGeolocationData() {
	if (!window.currentLocationFromHSGeolookup || window.currentLocationFromHSGeolookup.error) { // fetching the geoloc, if there is no data yet
		fetchGeolocation();
	}
}