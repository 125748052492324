import { getFormData, getPrevStepId, getNextStepId } from '../../../helpers';

export function skipSteps(oldStepId, newStepId, criteria = {}) {
	// Copy of newStepId
	let nextStepId = newStepId;
	const formData = getFormData();
	const nextStepName = document.querySelector(`#${newStepId}`).getAttribute('data-step-name');

	// To store unique test results from multiple results
	const shouldSkipParentStepResultSet = new Set(); // eslint-disable-line no-undef

	if (Object.keys(criteria).length) {
		Object.keys(criteria).some((parentStep) => {
			// If the next step is equal to parent step that has criteria
			if (parentStep === nextStepName) {
				if (criteria[parentStep]) {
					criteria[parentStep].map((linkedStep) => {
						const [key] = Object.keys(linkedStep);
						let shouldSkipParentStep;

						if (key !== 'data-vwo-test') {
							// Check the specific formData key's value is present in the linked step array
							shouldSkipParentStep = linkedStep[key].includes(formData[key]);
						} else {
							const dataVWO = document.querySelector('body').getAttribute('data-vwo-test');
							if (Array.isArray(linkedStep[key])) {
								const isControl = linkedStep[key].includes('control') && !dataVWO;
								const isVariation = linkedStep[key].includes(dataVWO);
								if (isControl || isVariation) {
									shouldSkipParentStep = true;
								}
							} else {
								if (dataVWO === linkedStep[key] || (linkedStep[key] === 'control' && !dataVWO)) {
									shouldSkipParentStep = true;
								}
							}
						}

						shouldSkipParentStepResultSet.add(shouldSkipParentStep);
					});
					// Only skip the step if all the mentioned/linked step conditions are true
					const skippedStep = document.querySelector(`[data-step-name=${parentStep}]`);
					if (shouldSkipParentStepResultSet.size === 1 && shouldSkipParentStepResultSet.has(true)) {
						skippedStep.classList.add('skipped');
						nextStepId = oldStepId === getPrevStepId(newStepId) ? getNextStepId(newStepId) : getPrevStepId(newStepId);
					} else {
						skippedStep.classList.remove('skipped');
					}
				}
			}
		});
	}

	if (shouldSkipParentStepResultSet.has(true) && Object.keys(criteria).includes(document.querySelector(`#${nextStepId}`).getAttribute('data-step-name'))) {
		return skipSteps(newStepId, nextStepId, criteria);
	}

	return nextStepId;
}
