/**
 * Unmark field as invalid
 * @param {Object} $field - element to unmark as invalid
 * @returns {Boolean} - false if no field provided
 */
export function unmarkFieldAsInvalid($field) {
	if (!$field) {
		return false;
	}
	const fieldParent = $field.parentElement.closest('.' + window.modForm.opts.errorParentCls);
	if (fieldParent) {
		fieldParent.classList.remove(window.modForm.opts.fieldErrorCls);
		if (window.modForm.opts.addAccessibilityUpdates) {
			if ($field.getAttribute('type') === 'radio') {
				const firstRadio = $field.closest('fieldset').querySelector('input[type="radio"]:first-child');
				firstRadio.setAttribute('aria-invalid', 'false');
				firstRadio.removeAttribute('aria-describedby');
			} else {
				$field.setAttribute('aria-invalid', 'false');
				$field.removeAttribute('aria-describedby');
			}
		}
	} else {
		$field.parentElement.classList.remove(window.modForm.opts.fieldErrorCls);
	}
}
