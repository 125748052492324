/**
 * Populate form input
 * @param {String} name - name of a field
 * @param {String} value - value of a field
 */
export function populateInput(name, value) {
	const $input = window.modForm.opts.form.querySelector('input[name="' + name + '"]');
	if ($input) {
		if ('INPUT' === $input.tagName && 'radio' === $input.type && $input.value === value) {
			$input.setAttribute('checked', true);
		} else if ('INPUT' === $input.tagName && 'checkbox' === $input.type) {
			$input.setAttribute('checked', true);
		} else if ('SELECT' === $input.tagName) {
			$input.querySelector('option[value="' + value + '"]').setAttribute('selected', true); // this doesn't break a select if such value is not among its options
		} else {
			$input.value = value;
			// zip1 field will be populated with zip or zip1 query
			if (name === 'zip') {
				const zip1 = window.modForm.opts.form.querySelector('input[name="zip1"]');
				if (zip1) {
					zip1.value = value;
				}
			}
		}
	}
}
