import { heapIdentify } from '../../helpers';

/**
 * #q[stepNumber] will be added when moving forward or backwards in the form
 * or #[stepName] will be added when moving forward or backwards in the form if `modForm.opts.useStepNameHash` is enabled)
 *
 * @param {Object} nextStepId - the html id of the next step
 */
export async function hashStepTracking(nextStepId) {
	const hasQueryParams = window.location.search.length > 0;

	if (hasQueryParams) {
		// We have to add an ending ampersand to protect the query params from saving the hash in the database
		const haveWeAlreadyAddedEndingAmpersand = window.location.search.substr(-1) !== '&';
		if (haveWeAlreadyAddedEndingAmpersand) {
			window.history.replaceState({}, document.title, window.location.href.split('#')[0] + '&');
		}
	}

	if (window.modForm.opts.useStepNameHash && typeof window.modForm.opts.formSwiperInstance === 'object') {
		const formSwiperSpeed = window.modForm.opts.formSwiperInstance?.params?.speed || window.modForm.opts.formSwiperInstance?.passedParams?.speed;

		if (formSwiperSpeed) {
			await new Promise(function(resolve) { // eslint-disable-line no-undef
				setTimeout(function() {
					const activeStep = window.modForm.getCurrentStep();
					const stepName = activeStep && activeStep.hasAttribute('data-step-name') ? activeStep.getAttribute('data-step-name') : null;

					if (stepName) {
						history.pushState(null, null, '#' + stepName);
						// Per Heap's recommendation in their site, we hit Heap Identify for each "pageview"
						// Temporal fix until we see better numbers
						heapIdentify();
					}

					resolve();
				}, formSwiperSpeed);
			});
		}
	} else if (nextStepId) {
		const extractNumber = /\d+/g;
		const stepNumber = nextStepId.match(extractNumber)[0];
		window.location.hash = 'q' + stepNumber;
	}
}
