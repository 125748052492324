/**
 * Mutation observer for A/B testing
 * @param {string} callback the callback function
 */
export function observeVWOVariation(callback) {
	const targetNode = window.modForm.opts.observedTargetNode;
	const config = window.modForm.opts.observerConfig;

	const mutationCallback = function(mutations, observer) {
		for (const mutation of mutations) {
			if (
				mutation.type === 'attributes'
				&& mutation.target.hasAttribute(window.modForm.opts.vwoVariationDataAttribute)
				&& mutation.target
					.getAttribute(window.modForm.opts.vwoVariationDataAttribute)
					.indexOf(window.modForm.opts.vwoVariationDataAttributeValue) > -1
			) {
				callback();
				observer.disconnect();
			}
		}
	};

	const observer = new MutationObserver(mutationCallback);
	observer.observe(targetNode, config);
}
