/**
 * Mark field is invalid
 * @param {Object} $field - field to mark as invalid
 * @returns {boolean} - false if no field provided
 */
export function markFieldAsInvalid($field) {
	if (!$field) {
		return false;
	}
	const fieldParent = $field.parentElement.closest('.' + window.modForm.opts.errorParentCls);
	if (fieldParent) {
		fieldParent.classList.add(window.modForm.opts.fieldErrorCls);
		if (window.modForm.opts.addAccessibilityUpdates) {
			if ($field.getAttribute('type') === 'radio') {
				const radioFieldsetId = $field.closest('fieldset').getAttribute('id');
				const $firstRadio = $field.closest('fieldset').querySelector('input[type="radio"]:first-child');
				$firstRadio.setAttribute('aria-invalid', 'true');
				$firstRadio.setAttribute('aria-describedby', 'error--' + radioFieldsetId);
				$firstRadio.focus();
			} else {
				let fieldId;
				if ($field.hasAttribute('id')) {
					fieldId = $field.getAttribute('id');
				} else {
					fieldId = $field.getAttribute('name');
				}
				$field.setAttribute('aria-invalid', 'true');
				$field.setAttribute('aria-describedby', 'error--' + fieldId);
			}
		}
	} else {
		$field.parentElement.classList.add(window.modForm.opts.fieldErrorCls);
	}
}
