import { isBadWord, isValidCompanyName, isEmailValid } from '../validators';
/**
 * Validate Field
 * @param {Object} $field - jQuery input element
 * @param {String} validationType - validation type to run: zip, email, phone, nonempty, regex, int,
 * @returns {Boolean} valid
 */
export function isFieldValid($field, validationType) {
	let isValid = true,
		value = $field.value,
		minLength,
		areaCode,
		error,
		preCode;
	const phoneErrorCheck = {
			areaCode: ['000', '800', '888', '866', '911'],
			preCode: ['555', '000', '911'],
			badNumbers: [
				'4192933931',
				'7137760656',
				'6315431074',
				'2814689701',
				'2814827603',
				'6318642656'
			]
		},
		validateRegexObj = {
			name: /[^A-Za-z'\- .à-Ż]/,
			zip: /^\d{5}(?:-\d{4})?$/
		},
		multipleSpace = /\s\s+/g,
		REGEXPHONE = /^[2-9][0-9]{2}[2-9][0-9]{6}$/,
		REGEXREPEAT3 = /(.+)(?=\1{2})/,
		REGEXREPEAT5 = /(.+)(?=\1{4})/;

	if (typeof validateRegexObj[validationType] !== 'undefined') {
		if (validationType === 'name') {
			isValid = isValid && !validateRegexObj[validationType].test(value);
		} else {
			isValid = isValid && validateRegexObj[validationType].test(value);
		}
	}
	/* This switch statement tests the remaining cases not validated from validateRegexObj */
	switch (validationType) {
	case 'email':
		isValid = isValid && isEmailValid(value, $field) && value.search('rediffmail') === -1;
		break;
	case 'phone':
		value = value.replace(/[()\s-]/g, '');
		areaCode = value.substr(0, 3),
		preCode = value.substr(3, 3),
		error = false;
		error = phoneErrorCheck.areaCode.includes(areaCode) || phoneErrorCheck.preCode.includes(preCode) || areaCode.indexOf('11') !== -1;
		isValid = isValid && !error && !phoneErrorCheck.badNumbers.includes(value) && REGEXPHONE.test(value) && !REGEXREPEAT5.test(value);
		break;
	case 'address':
		minLength = $field.getAttribute('data-address-min') && parseInt($field.getAttribute('data-address-min'), 10) ? parseInt($field.getAttribute('data-address-min'), 10) : 5;
		isValid = isValid && value.length >= minLength && !isBadWord(value) && !multipleSpace.test(value);
		break;
	case 'name':
		minLength = $field.getAttribute('data-name-min') && parseInt($field.getAttribute('data-name-min'), 10) ? parseInt($field.getAttribute('data-name-min'), 10) : 2;
		isValid = isValid && value.length >= minLength && !isBadWord(value) && !REGEXREPEAT3.test(value);
		break;
	case 'companyName':
		isValid = isValid && isValidCompanyName(value);
		break;
	case 'nonempty':
		minLength = $field.getAttribute('data-nonempty-min') && parseInt($field.getAttribute('data-nonempty-min'), 10) ? parseInt($field.getAttribute('data-nonempty-min'), 10) : 1;
		isValid = isValid && value && value.length >= minLength ? true : false;
		break;
	case 'regex':
		isValid = isValid && new RegExp($field.getAttribute('data-regex')).test(value);
		break;
	case 'ein':
		isValid = isValid && (value === '' || /^\d{2}-\d{7}$/.test(value));
		break;
	case 'int':
		value = parseInt(value, 10);
		if (!Number.isInteger(value)) {
			isValid = false;
		}
		if ($field.getAttribute('data-min') && parseInt($field.getAttribute('data-min'), 10) && value < parseInt($field.getAttribute('data-min'), 10)) {
			isValid = false;
		}
		if ($field.getAttribute('data-max') && parseInt($field.getAttribute('data-max'), 10) && value > parseInt($field.getAttribute('data-max'), 10)) {
			isValid = false;
		}
		break;
	default:
		break;
	}
	return isValid;
}
