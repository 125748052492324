import { switchFormLoadingState } from '../../helpers';
/**
 * Get Electric Utility Providers by State
 * @param {String} state - state name
 * @param {Function} callback - callback function
 */
export function getUtilityProvidersByState(state, callback) {
	switchFormLoadingState(true);

	let response = {};
	const request = new XMLHttpRequest();
	request.open('GET', window.modUtils.getApiDomain() + '/v1/lookups/utility-providers?filters[state]=' + state);
	request.send();

	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				let skipStep;
				response.success = true;

				// if less than one utility provider is returned skip utility provider question
				if (response.success && response.data.length > 1) {
					callback(skipStep, response.data);
				} else if (response.success === true && response.data.length <= 1) {
					skipStep = true;

					callback(skipStep);

					const utilityProvider = document.createElement('input');
					utilityProvider.type = 'hidden';
					utilityProvider.name = 'utilityProviderId';
					utilityProvider.value = '999999';
					utilityProvider.setAttribute('data-required', 'nonempty');

					window.modForm.opts.form.append(utilityProvider);
				}

				switchFormLoadingState(false);
			}
		} else {
			if ('function' === typeof callback) {
				callback({});
			}
		}
	});
	request.addEventListener('error', function() {
		console.error('Something went wrong');
	});
}
