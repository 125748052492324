/**
 * Check form configuration for required settings
 * @returns {Bool} - true if valid, false if not
 */
export function isConfigValid() {
	const errors = [];
	if (window.modForm.opts.vertical === null) {
		errors.push('Please define the vertical.');
	}
	if (window.modForm.opts.form === null || !window.modForm.opts.form.length) {
		errors.push('Please define the form element.');
	}
	if (!document.querySelector(window.modForm.opts.stepsSelector)) {
		errors.push('Please form steps.');
	}
	if (errors.length) {
		alert(errors.join('\n'));
		return false;
	}
	return true;
}
