import { getFormFieldNameFromMappings } from '../../../config';

export const prePopFormWithEddie = function(eddieResponse) {
	if (Object.keys(eddieResponse).length) {
		Object.entries(eddieResponse).forEach(function([key, value]) {
			if (value) {
				const form = window.modForm.opts.form;
				const formInputs = form.querySelectorAll(`input[name=${key}], select[name=${key}]`).length ? form.querySelectorAll(`input[name=${key}], select[name=${key}]`) : form.querySelectorAll(`input[name=${getFormFieldNameFromMappings(key)}], select[name=${getFormFieldNameFromMappings(key)}]`);
				formInputs.forEach(function(input) {
					if (input) {
						// remove multiple spaces from text field
						if (input.type === 'text') {
							value = value.replace(/\s\s+/ig, ' ');
						}

						if (input.type === 'radio' && (input.value === value || value.includes(input.value))) {
							input.checked = true;
						} else if (input.name === 'OwnHome' && input.type === 'checkbox') {
							input.checked = value.toLowerCase() === 'yes';
							input.value = value;
						} else if (input.type === 'select-one') {
							input.value = value;
						} else if (['text', 'email', 'tel', 'hidden'].includes(input.type)) {
							input.value = value;
						}
					}
				});
			}
		});
	}
};
