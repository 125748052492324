import { isQsSiteEligibleForHrc } from '../../../validators';
/*
 * Init for showing "Your Information is secure" or "Encrypted form, free and competitive quote" in PII steps
 */
export function initShowSecureText() {
	let secureText = '<div class="secure-text secure-text--dark"><b>Your information is secure</b></div>';
	if (window.modForm.opts.useEncryptedSecureText) {
		secureText = '<div class="secure-text secure-text--dark secure-text--italics"><b>Encrypted form, free and competitive quotes</b></div>';
		if (!isQsSiteEligibleForHrc()) {
			secureText = '<div class="secure-text secure-text--dark secure-text--italics"><b>Encrypted form, free and competitive quote</b></div>';
		}
	}
	window.modForm.opts.secureTextStepsSelector.forEach(function(stepSelector) {
		let $stepButtonSelector = document.querySelector(`${stepSelector} .btn`);
		if (window.modForm.opts.useEncryptedSecureText) {
			$stepButtonSelector = document.querySelector(stepSelector);
		}
		if ($stepButtonSelector) {
			if (window.modForm.opts.secureTextPosition === 'below-cta') {
				$stepButtonSelector.insertAdjacentHTML('afterend', secureText);
			} else {
				$stepButtonSelector.insertAdjacentHTML('beforebegin', secureText);
			}
		}
	});
}
