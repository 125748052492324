import { prepopFormFromURL } from '../../helpers';
/**
 * function to get the saved query string values from the session storage
 * @returns {Object} - query string values
 */
export function getQueryParamsFromSessionStorage() {
	const queryParams = window.sessionStorage ? window.sessionStorage.getItem('queryParams') : null;
	const parsedQueryParams = queryParams ? JSON.parse(queryParams) : null;
	if (parsedQueryParams) {
		window.modForm.opts.getVars = parsedQueryParams;
		prepopFormFromURL();
	}
	return parsedQueryParams;
}
