import { backButtonClickCallback } from '../../../helpers';
/**
 * Init back buttons
 * @param {boolean} [addEvent=true] - Determines whether to add an event listener to the back buttons
 */
export function initBackButtons(addEvent = true) {
	if (window.modForm.opts.backButtons.length) {
		window.modForm.opts.backButtons.forEach((backButton) => {
			if (addEvent) {
				backButton.addEventListener('click', backButtonClickCallback);
			} else {
				backButton.removeEventListener('click', backButtonClickCallback);
			}
		});
	}
}
