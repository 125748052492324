/**
 * Get form data as an object
 * @returns {Object} - form data as an object
 */
export function getFormData() {
	const formDataArray = Array.from(new FormData(window.modForm.opts.form));
	let formData = {};

	formData = Object.fromEntries(formDataArray);

	const croData = {};

	window.modForm.opts.form.querySelectorAll('input[data-cro]').forEach(function(element) {
		let type = element.type;
		type = type === undefined ? 'textarea' : type;
		const name = element.name;
		const val = element.value;

		if (((type === 'radio' || type === 'checkbox') && element.checked) || ((type === 'text' || type === 'textarea' || type === 'tel') && val !== '')) {
			croData[name] = val;
		}
	});

	if (Object.keys(croData).length !== 0) {
		formData.CROTag = JSON.stringify(croData);
	}

	// Process homeowner checkbox
	if (!formData.homeowner || formData.homeowner === '' || formData.homeowner === 'on') {
		const $homeownerCheckbox = window.modForm.opts.form.querySelector('input[type="checkbox"][name="homeowner"]');

		if ($homeownerCheckbox && $homeownerCheckbox.length) {
			formData.homeowner = $homeownerCheckbox.checked ? 'yes' : 'no';
		}
	}

	return formData;
}
