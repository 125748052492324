import { appendFormInput, saveUserInfoToCookie } from '../../helpers';
/**
 * Handles change on inputs for cookie
 */
export function initUserInformationStorage() {
	const $userInfoObjs = window.modForm.opts.form.querySelectorAll('[data-save]');

	if ($userInfoObjs.length > 0) {
		$userInfoObjs.forEach(function(elem) {
			appendFormInput(elem.name, window.modForm.userObj[elem.name]);

			elem.addEventListener('blur', function() {
				saveUserInfoToCookie(this.name, this.value);
			});
		});
	}
}
