/**
 * Capitalizing and formatting trade string correctly to match with the option text
 * @param {String} firstString - String that needs all words to get capitalized
 * @returns {String} Capitalized string
 */
export function capitalize(firstString) {
	const words = firstString.split(' ');
	let finalString = '';

	words.forEach(function(word) {
		finalString += word.charAt(0).toUpperCase() + word.slice(1) + ' ';
	});

	return finalString.trim();
}
