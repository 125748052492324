export const additionalOptions = {
	// set true before sending data to server and back to false when response is returned from server
	isSubmitting: false,
	isPhoneValid: false,
	isZipValid: false,
	areLeadTokenScriptsLoaded: false,
	loadedScripts: [],
	optedIn: false,
	// set true if you want inline validation on input with zip. be warned this causes many edge cases you will need to fix
	inlineZip: false,
	mutationObserverTarget: document.body,
	mutationObserverConfig: {
		attributes: true
	},
	projexURL: null,
	redirectStart: null,
	zipEditControlCls: '.zip-control__edit, .change-zip-control, .step__city-state, .form__city-state, .zip-control, .form-group__city-state',
	zipEditInput: 'input[name="zip1"], input[name="inertZip"]',
	zipControlButton: document.querySelector('.zip-control__button'),
	retryZipValidation: false,
	preventDuplicateSubmission: false,
	getTime: new Date().getTime(),
	radioButtonClickedByMouse: false,
	includeDuplicateSubmissionExperience: true,
	homeownerLeadConversionProps: {}
};
