/**
 * Custom function to mask phone fields
 * @param {Object} elements - object of fields
 * @returns {Boolean} - false if no elements defined
 */
export function maskPhoneFields(elements) {
	const allowedKeys = ['Control', 'Tab', 'ArrowLeft', 'ArrowDown', 'ArrowRight', 'ArrowUp', 'Backspace', 'Enter'];

	if (!elements.length) {
		return false;
	}

	elements.forEach(function(element) {
		const nonDigitsRegex = /\D/g;
		let value = element.value; // eslint-disable-line prefer-const

		if (value.length === 10) {
			let mask = /(\d{3})(\d{3})(\d{,4})?/, // eslint-disable-line prefer-const
				newValues = '($1) $2-$3'; // eslint-disable-line prefer-const

			element.value = value.replace(mask, newValues);
		}

		element.addEventListener('keydown', function(e) {
			if (element.value.length === 14 && !allowedKeys.includes(e.key)) {
				e.preventDefault();
			}
		});

		element.addEventListener('keyup', function() {
			let value = element.value, // eslint-disable-line no-shadow
				mask = /(\d{1,3})/, // eslint-disable-line no-shadow
				newValues = '($1'; // eslint-disable-line no-shadow

			value = value.replace(nonDigitsRegex, '').substr(0, 10);
			const valueLength = value.length;

			if (valueLength > 3 && valueLength <= 6) {
				mask = /(\d{3})(\d{,3})?/;
				newValues += ') $2';
			} else if (valueLength > 6) {
				mask = /(\d{3})(\d{3})(\d{,4})?/;
				newValues += ') $2-$3';
			}

			element.value = value.replace(mask, newValues);
		});
	});
}
