/**
 * Get Cross sell API domain based on whether this is testing or production env
 * @returns {String} QS domain to use
 */
export function getCrossSellApiDomain() {
	let domain = 'https://hsapi.quinstage.com/';
	const env = window.modUtils.getEnv();

	if (env === 'production') {
		domain = 'https://hsapi.quinstreet.com/';
	}

	return domain;
}
