/**
 * Switch form submitting state
 * @param {String} submitState - Indicates current status for form submit
 */
export function switchFormSubmitState(submitState) {
	if (submitState === 'submitting') {
		window.modForm.opts.form.classList.add(window.modForm.opts.formSubmittingCls);
	} else if (submitState === 'submitted') {
		window.modForm.opts.form.classList.add(window.modForm.opts.formSubmittedCls);
	} else if (submitState === 'error') {
		window.modForm.opts.form.classList.add(window.modForm.opts.formSubmitErrorCls);
	} else {
		window.modForm.opts.form.classList.remove(
			window.modForm.opts.formSubmittingCls,
			window.modForm.opts.formSubmittedCls,
			window.modForm.opts.formSubmitErrorCls
		);
		document.body.classList.remove('submitted');
	}
}
