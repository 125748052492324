/**
 * Email
 * @param {String} email - email address to validate
 * @returns {Boolean} isFreeEmail
 */
export function isFreeEmail(email) {
	let freeEmail = false;
	const freeDomains = ['gmail', 'hotmail', 'msn', 'aol', 'yahoo', 'ymail', 'comcast', 'icloud'];

	if (email) {
		const emailAddress = email.toLowerCase();
		freeEmail = freeDomains.some(domain => emailAddress.includes(domain));
	}

	return freeEmail;
}
