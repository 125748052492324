/**
 * Handles show/hide hvacFuelType block when hvacSystemType changes
 */
export function initHvacFuelTypeBlock() {
	// This checks whether selected hvac system type requires fuel type or not,
	// and shows or hides fuel type block
	const _check = function() {
		let hvacSystemType;

		if (window.modForm.opts.hvacSystemTypeField.length) {
			window.modForm.opts.hvacSystemTypeField.forEach(function(el) {
				if ('radio' === el.type) {
					hvacSystemType = el.checked && el.value.trim();
				} else {
					hvacSystemType = el.value.trim();
				}
			});
		}

		if (hvacSystemType) {
			if (window.modForm.opts.hvacSystemTypesWithFuel.indexOf(hvacSystemType) > -1) {
				window.modForm.opts.hvacFuelTypeBlock[0].style.display = 'block';
			} else {
				window.modForm.opts.hvacFuelTypeBlock[0].style.display = 'hidden';
			}
		}
	};

	if (window.modForm.opts.hvacSystemTypeField.length && window.modForm.opts.hvacFuelTypeBlock.length) {
		window.modForm.opts.hvacSystemTypeField.forEach(function(el) {
			el.addEventListener('change', function() {
				_check();
			});
		});

		_check();
	}
}
