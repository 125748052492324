export const updateTabIndexes = function() {
	if (window.modForm.opts.steps && window.modForm.opts.steps.length) {
		window.modForm.opts.steps.forEach(function(step) {
			if (step) {
				const stepFields = step.querySelectorAll('input, select, textarea');
				if (stepFields.length) {
					stepFields.forEach(function(field) {
						if (field) {
							if (step.classList.contains(window.modForm.opts.stepActiveCls)) {
								field.removeAttribute('tabindex');
							} else {
								field.setAttribute('tabindex', '-1');
							}
						}
					});
				}
			}
		});
	}
};
