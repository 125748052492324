import { nextButtonClickCallBack, zipKeyDownCallBack, tabKeyDownCallBack } from '../../../helpers';
/**
 * Preventing the form to continue with rage clicks / keystrokes
 * @param {boolean} [addEvents=true] - Determines whether to add event listeners
 * @returns {boolean|undefined} - Returns false if no form / button is present, otherwise does not return anything.
 */
export function initNextButtonAbusePrevention(addEvents = true) {
	const form = window.modForm.opts.form;
	const primaryButtons = form.querySelectorAll('.btn-primary, .btn--primary');

	if (!form) {
		return false;
	}

	if (primaryButtons.length) {
		primaryButtons.forEach(function(button) {
			if (!button) {
				return false;
			}

			if (addEvents) {
				button.addEventListener('click', nextButtonClickCallBack);
			} else {
				button.removeEventListener('click', nextButtonClickCallBack);
			}
		});
	}
	if (form.querySelector('input[name="zip"]')) {
		if (addEvents) {
			form.querySelector('input[name="zip"]').addEventListener('keydown', zipKeyDownCallBack);
		} else {
			form.querySelector('input[name="zip"]').removeEventListener('keydown', zipKeyDownCallBack);
		}
	}
	if (addEvents) {
		document.body.addEventListener('keydown', tabKeyDownCallBack);
	} else {
		document.body.removeEventListener('keydown', tabKeyDownCallBack);
	}
}
