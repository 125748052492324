/**
 * Track form step view in GA
 * @param {String} stepId - step ID for tracking
 */
export function trackFormStepGA(stepId) {
	const stepGaName = document.getElementById(stepId).getAttribute('data-ga-step-name') || stepId; // Check if this step has dedicated GA step name

	window.modUtils.gaSend({
		hitType: 'pageview',
		page: window.location.pathname + '?step=' + stepGaName,
		title: document.title + ' (' + stepGaName + ')'
	}, window.modForm.opts.gaTracker);
}
