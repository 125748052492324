export function preProcessPayload(data) {
	const payload = [];
	let tempKey;
	if (data && Object.entries(data) && Object.entries(data).length) {
		Object.entries(data).forEach(([name, value]) => {
			if (typeof value === 'object') {
				tempKey = name;

				delete data[name];

				Object.entries(value).forEach(function([childKey, childValue]) {
					if (childValue) {
						payload.push([`${tempKey}[${childKey}]`, childValue]);
					}
				});
			} else {
				payload.push([name, value]);
			}
		});
	}
	return payload;
}
