/**
 * Add attributes to radio input necessary for regression testing
 */
export function addRegressionTestingAttributes() {
	const $radioLabels = window.modForm.opts.form.querySelectorAll('.radio label');

	$radioLabels.forEach(function(element) {
		const $associatedInput = element.querySelector('input');

		// So that we can distinguish each input in Katalon each label attribute should be the input name and value
		element.setAttribute('data-automated-testing', $associatedInput.getAttribute('name') + ' ' + $associatedInput.value);
	});
}
