import { getQSApiDomain, heapIdentify, getPhoneAssignmentData } from '../../helpers';
/**
 * Transform Quadlink
 */
export function transformQuadlink() {
	let response = {};
	const request = new XMLHttpRequest(),
		apiUrl = getQSApiDomain() + window.modForm.opts.trafficDetailsApi;

	request.open('POST', apiUrl);
	request.setRequestHeader('Content-Type', 'application/json');
	request.send(JSON.stringify(window.modForm.opts.quadLinkData));
	request.addEventListener('load', async function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				for (const value in response) {
					if (window.modForm.opts.qsParams.includes(value) && response.hasOwnProperty(value)) { // eslint-disable-line no-prototype-builtins
						window.modForm.opts.quadLinkParams[value] = response[value];
					}
				}

				if (window.modForm.opts.phoneAssignmentConfig.showPhoneAssignmentNumber) {
					getPhoneAssignmentData(response);
				}

				heapIdentify();
				window.modForm.opts.quadLinkHasBeenTransformed = true;

				if (window.heap) {
					window.Modalytics.heap('addEventProperties', window.modForm.opts.quadLinkParams);

					if (response.AffiliateKey) {
						window.Modalytics.heap('addEventProperties', {'AffiliateKey': response.AffiliateKey});
						window.Modalytics.heap('addUserProperties', {'AffiliateKey': response.AffiliateKey});
					}
				} else {
					await new Promise(resolve => { // eslint-disable-line no-undef
						setTimeout(function() {
							if (window.heap) {
								if (response.AffiliateKey) {
									window.Modalytics.heap('addEventProperties', {'AffiliateKey': response.AffiliateKey});
									window.Modalytics.heap('addUserProperties', {'AffiliateKey': response.AffiliateKey});
								}

								window.Modalytics.heap('addEventProperties', window.modForm.opts.quadLinkParams);
							}
							resolve();
						}, 250);
					});
				}
			}
		}
	});
	request.addEventListener('error', function() {
		console.error('Something went wrong');
	});
}
