/**
 * Getting the ELOQUA cookie and looking for the GUID value
 * @returns {String} - Guid from cookie
 */
export function elqGetGuidCookieValue() {
	const elqCookie = window.modUtils.getCookie('ELOQUA');
	if (elqCookie) {
		const subCookies = elqCookie.split('&');

		for (let l = 0; l < subCookies.length; l++) {
			const subCookie = subCookies[l].split('=');

			if (subCookie.length === 2 && subCookie[0] === 'GUID') {
				return subCookie[1];
			}
		}
	}

	return '';
}
