/**
 * Check if a bad word was used (must use non forEach for return to work)
 * @param {String} stringToTest - string that is general used for address, name and any other string field
 * @returns {Boolean} valid
 */
export function isBadWord(stringToTest) {
	const ADDRESS_ALLOWED_CHARS = /[^a-z0-9.'#-s]/i,
		badWords = [
			'anal',
			'anus',
			'arse',
			'ass',
			'assfucker',
			'asshole',
			'balls',
			'ballsack',
			'bastard',
			'biatch',
			'bitch ass',
			'bitch',
			'bitchin',
			'bitching',
			'blow job',
			'blowjob',
			'boner',
			'boob',
			'booger',
			'booob',
			'boooob',
			'booooob',
			'booooooob',
			'breast',
			'butt fuck',
			'butt',
			'butthole',
			'chink',
			'clit',
			'clitoris',
			'cock sucker',
			'cock',
			'cocksuck',
			'cocksucked',
			'cocksucker',
			'cocky',
			'crap',
			'cum',
			'cumshot',
			'cunt',
			'damn',
			'darn',
			'derp',
			'dickhead',
			'dildo',
			'dlck',
			'douche',
			'douchebag',
			'eatme',
			'ejaculate',
			'fag',
			'faggot',
			'fat',
			'fatass',
			'fatty',
			'fuck off',
			'fuck',
			'fucked',
			'fucker',
			'fuckhead',
			'fuckyou',
			'goddamn',
			'haha',
			'hahaha',
			'hahahaha',
			'horniest',
			'horny',
			'i\'m',
			'infection',
			'jack off',
			'jackoff',
			'jerk off',
			'jizz',
			'listed',
			'masturbate',
			'me off',
			'meh off',
			'meoff',
			'mother fucker',
			'motherfuck',
			'motherfucker',
			'muff',
			'myball',
			'nazi',
			'nigga',
			'niggah',
			'niggaz',
			'nigger',
			'numbnut',
			'nutsack',
			'nutz',
			'orgasm',
			'pecker',
			'penis',
			'pimple',
			'piss',
			'pissy',
			'poop',
			'porn',
			'prick',
			'prickly',
			'pube',
			'pussi',
			'pussy',
			'queef',
			'rectum',
			'retard',
			'schlong',
			'scrotum',
			'semen',
			'sex',
			'sexy',
			'shit',
			'shithead',
			'shitty',
			'skank',
			'slut',
			'smegma',
			'smut',
			'snatch',
			'spammer',
			'sponge',
			'spongebob',
			'spunk',
			'squarepant',
			'stupid',
			'suck',
			'sucker',
			'teet',
			'testicle',
			'thisisgay',
			'titti',
			'titty',
			'troll',
			'turd',
			'twat',
			'ugly',
			'vagina',
			'whore',
			'xxx',
			'your',
			'your mother'
		];
	stringToTest = stringToTest.replace(ADDRESS_ALLOWED_CHARS);
	stringToTest = stringToTest.toLowerCase();
	const wordArray = stringToTest.split(' ');
	for (let wordIndex = 0; wordIndex < wordArray.length; wordIndex++) {
		for (let badIndex = 0; badIndex < badWords.length; badIndex++) {
			if (wordArray[wordIndex] === badWords[badIndex]) {
				return true;
			}
		}
	}
	return false;
}
