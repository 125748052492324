import { switchFormLoadingState } from '../../helpers';
/**
 * Get Electric Utility Providers by Zip
 * @param {String} zip - zip code
 * @param {Function} callback - callback function
 */
export function getUtilityProvidersByZip(zip, callback) {
	switchFormLoadingState(true);
	let response = {};
	const defaultResponse = [{
		utilityProviderId: 999999,
		name: 'Other'
	}];
	const request = new XMLHttpRequest();
	request.open('GET', window.modUtils.getApiDomain() + '/v1/lookups/utility-providers?filters[zip]=' + zip);
	request.send();
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				let skipStep;
				response.success = true;
				// if less than one utility provider is returned skip utility provider question
				if (response.success && response.data.length > 1) {
					callback(skipStep, response.data);
				} else if (response.success === true && response.data.length <= 1) {
					skipStep = true;
					callback(skipStep, response.data);
					const utilityProvider = document.createElement('input');
					utilityProvider.type = 'hidden';
					utilityProvider.name = 'utilityProviderId';
					utilityProvider.value = '999999';
					utilityProvider.setAttribute('data-required', 'nonempty');
					window.modForm.opts.form.append(utilityProvider);
				}
			} else {
				if ('function' === typeof callback) {
					callback(true, defaultResponse);
				}
			}
		}
	});

	request.addEventListener('error', function() {
		console.error('Something went wrong');
		if ('function' === typeof callback) {
			callback(true, defaultResponse);
		}
	});

	request.addEventListener('loadend', function() {
		switchFormLoadingState(false);
	});
}
