import { opts, additionalOptions } from '../config';
import * as helpers from '../helpers';
import * as init from '../init/homeowner';
import { isZipCodeValid, isCurrentStepLast, isCurrentStepSecondToLast } from '../validators';

export const modForm = {
	...additionalOptions,
	...opts,
	...init,
	...helpers,
	isZipCodeValid,
	isCurrentStepLast,
	isCurrentStepSecondToLast
};
