import { preventSkip } from '../../../helpers';

export function tabKeyDownCallBack(e) {
	const form = window.modForm.opts.form;
	if (e.key === 'Tab') {
		document.addEventListener('focus', function(el) {
			if (form.contains(el.target)) {
				form.classList.add('disableAutofill');
			}
			el.stopPropagation();
		}, true);
		preventSkip(e);
	}
}
