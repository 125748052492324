/**
 * Saves user information to cookie
 * @param {String} name - key name for the object being stored on the cookie
 * @param {String} value - value for the object being stored on the cookie
 */
export function saveUserInfoToCookie(name, value) {
	if (name.toLowerCase().includes('zip')) {
		window.modForm.userObj.zip = value;
	} else {
		window.modForm.userObj[name] = value;
	}

	window.modUtils.setCookie('userInfo', JSON.stringify(window.modForm.userObj), 526740);
}
