/**
 * Set Cookie with Heap Session ID
 * @param {String} resultsPage - getting final results page URL for cookie
 */
export function createHeapSessionIdCookie(resultsPage) {
	setTimeout(function() {
		if (window.modUtils.heapCookie) {
			window.modUtils.setCookie(
				window.modUtils.heapCookieData.sessionId,
				JSON.stringify({ initialTimestamp: window.modForm.getTime, resultsPage: resultsPage })
			);
		}
	}, 30);
}
