import { getTradeFromUrl, capitalize } from '../../../helpers';
/**
 * Update project class dropdown if there's a trade specified on the url with the ?trade param
 */
export function populateTradeFromURL() {
	let trade = getTradeFromUrl();
	const $primaryProjectClass = window.modForm.opts.form.querySelector('select[name="primaryProjectClass"]');

	if (trade && $primaryProjectClass) {
		trade = trade === 'hvac' ? 'HVAC' : capitalize(trade.replace(/-/g, ' ')).trim();
		$primaryProjectClass.querySelectorAll('option').forEach(function(option) {
			if (option.text === trade) {
				option.selected = true;
			}
		});
	}
}
