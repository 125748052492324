import { appendFormInput, getTcpaElementContent } from '../../../helpers';
/**
 * Add predefined data to the form
 */
export function addRequiredQSFieldsToForm() {
	const requiredFields = {
		HomePhoneConsentLanguage: window.modForm.opts.tcpaCopy && window.modForm.opts.tcpaCopy.length ? Array.from(window.modForm.opts.tcpaCopy).map((tcpa) => getTcpaElementContent(tcpa).trim().replace(/(<([^>]+)>)/ig, '').replace(/\n/g, '')) : ''
	};

	Object.keys(requiredFields).forEach((key) => {
		appendFormInput(key, requiredFields[key]);
	});
}
