import { parseHTMLFromString } from '../../helpers';
/**
 * Load and append a lead token script to the body of the page
 * @param {string} scriptURL URL of the script to be loaded
 */
export function appendLeadTokenScriptToBody(scriptURL) {
	const request = new XMLHttpRequest();
	request.open('GET', scriptURL);
	request.send();

	request.addEventListener('load', function() {
		if (this.response) {
			if (this.readyState === 4 && this.status === 200) {
				const scriptNode = parseHTMLFromString(this.response);
				document.body.appendChild(scriptNode);
			}
		}
	});
	request.addEventListener('error', function() {
		console.error('Something went wrong');
	});
}
