/**
 * Init the change of form elements based on URL params
 */
export function initFormDynamicContent() {
	const urlParams = window.modForm.opts.getVars,
		trade = window.modForm.opts.vertical;

	const mapping = {
		title: function(text) {
			const $pageTitle = document.querySelector('[data-theme-template="title"]');
			document.title = text;
			$pageTitle.innerText = text;
		},
		subtitle: function(text) {
			const $pageSubtitle = document.querySelector('[data-theme-template="subtitle"]');
			$pageSubtitle.innerText = text;
		},
		formtitle: function(text) {
			const $formTitle = document.querySelector('[data-theme-template="formtitle"]');
			$formTitle.innerText = text;
		},
		companyname: function(text) {
			const $companyName = document.getElementById('company-name'),
				extraText = trade === 'hvac' ? '' : ' from';
			text = text.slice(0, 1).toUpperCase() + text.slice(1); // Capitalizes Company Name
			$companyName.innerText = extraText + ' ' + text;
		},
		dt: function(dt) {
			const $dynamicText = document.getElementsByClassName('dynamic-text'),
				dynamicTextArray = dt.split('|');
			let newText;

			for (let i = 0; i < dynamicTextArray.length; i++) {
				newText = dynamicTextArray[i];

				if (newText) {
					$dynamicText[i].innerText = newText;
				}
			}
		},
		vp: function(vp) {
			const $valueProps = document.querySelectorAll('[data-theme-template="vp"]'),
				valuePropsArray = vp.split('|');
			let newText;

			for (let i = 0; i < valuePropsArray.length; i++) {
				newText = valuePropsArray[i];

				if (newText) {
					$valueProps[i].innerText = newText;
				}
			}
		}
	};

	Object.keys(mapping).forEach((key) => {
		if ('string' === typeof urlParams[key] && urlParams[key] !== '') {
			mapping[key](urlParams[key]);
		}
	});
}
