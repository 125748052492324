/**
 * Populating TCPA label with blurbs fetched with a getTCPAConsent API call from Sitegenie sending the trade and hostname as parameters
 */
export function populateTCPAField() {
	const tcpaCopy = window.modForm.opts.form.querySelector('#tcpa-copy');

	if (tcpaCopy && tcpaCopy.hasAttribute('data-tcpa-loaded')) {
		return;
	}

	const vertical = window.modForm.opts.vertical ? window.modForm.opts.vertical.toLowerCase() : '';
	const service = vertical.replace(' ', '_'),
		page = window.location.hostname.replace(/^qa./, '').replace(/^www./, ''),
		apiEnv = 'https://' + (window.modUtils.getEnv() === 'production' ? 'hs.leadpost.net/' : 'hsleadpost1.quinstage.com/'),
		tcpaURL = apiEnv + 'coreg/getTCPAConsent',
		params = {
			website: page,
			service: service,
			affiliateKey: '0',
			c_level: 'default' // eslint-disable-line camelcase
		};
	function ajaxUpdateTCPA() {
		let response = {};
		const request = new XMLHttpRequest();
		request.open('GET', `${tcpaURL}?${new URLSearchParams(params).toString()}`);
		request.overrideMimeType('text/plain; charset=ISO-8859-15');
		request.send();
		request.addEventListener('load', function() {
			if (this.response) {
				response = { ...response, ...JSON.parse(this.response) };
				if (this.readyState === 4 && this.status === 200) {
					let finalTCPA = response.tcpa.replace(/QuinStreet/g, 'Modernize');
					tcpaCopy.innerHTML = finalTCPA;
					tcpaCopy.setAttribute('data-tcpa-loaded', true);

					const HomePhoneConsentLanguage = window.modForm.opts.form.querySelector('input[type="hidden"][name="HomePhoneConsentLanguage"]');
					const tcpaText = window.modForm.opts.form.querySelector('input[type="hidden"][name="tcpaText"]');

					finalTCPA = finalTCPA.replace(/(<([^>]+)>)/ig, '');

					if (HomePhoneConsentLanguage) {
						HomePhoneConsentLanguage.value = finalTCPA;
					}

					if (tcpaText) {
						tcpaText.value = finalTCPA;
					}
					window.modForm.opts.isTCPAPopulated = true;
				}
			}
		});
		request.addEventListener('error', function() {
			console.error('Something went wrong');
		});
	}
	if ('IdlizeQueue' in window) {
		window.IdlizeQueue.pushTask(() => {
			ajaxUpdateTCPA();
		});
	} else {
		ajaxUpdateTCPA();
	}
}
