/**
 * Update all possible location displayed elements on page based on updated zipcde, except for user-location elements inside reviews
 * @param {Object} geoData - The user's geographical data
 */
export function populateLocationFieldsDynamically(geoData) {
	window.modForm.opts.userLocation = geoData.city + ', ' + geoData.state;
	const userLocation = document.querySelectorAll('.user-location:not(.reviews-block .user-location)');
	if (userLocation.length) {
		userLocation.forEach(function($userLocation) {
			if ($userLocation) {
				$userLocation.innerHTML = window.modForm.opts.userLocation;
			}
		});
	}

	if (document.querySelector('.user-city')) {
		document.querySelector('.user-city').innerHTML = geoData.city;
	}
}
