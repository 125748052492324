/**
 * If user encounters JS error and session refreshes, send initial query params & clean up empty form data
 * @param {Object} formData - Object of form data
 * @returns {Object} Processed formData
 */
export function useInitialQueryParamsFromSessionStorage(formData) {
	if (!formData) {
		return;
	}

	if (window.modForm.opts.ignoreQueryParamsInSessionStorage) {
		return formData;
	}

	let hasEmptyDefaultParams = false;
	const currentParams = window.modUtils.getUrlParamsToObject();
	const defaultFieldParams = ['zip=', 'firstName=', 'lastName=', 'address=', 'zip1=', 'city=', 'state=', 'homePhone=', 'email=', 'leadToken='];

	for (const key in currentParams) {
		if (currentParams.hasOwnProperty(key) && defaultFieldParams.includes(key)) { // eslint-disable-line no-prototype-builtins
			if (currentParams[key] === null) {
				delete formData[key];
				hasEmptyDefaultParams = true;
			}
		}
	}

	if (hasEmptyDefaultParams) {
		let queryParams = window.sessionStorage.getItem('queryParams');
		if (queryParams) {
			try {
				queryParams = JSON.parse(queryParams);

				formData = Object.assign(formData, queryParams);
				formData.usingQueryParamsFromSessionStorage = true;
				window.sessionStorage.removeItem('queryParams');
				window.modUtils.heap('track', ['Used Initial Query Params from Session Storage', {
					queryParamsObject: queryParams
				}]);
			} catch (e) {
				window.modUtils.heap('track', ['Error Parsing Initial Query Params', {
					queryParamsObject: queryParams,
					error: e.message
				}]);
			}
		}
	}

	return formData;
}
