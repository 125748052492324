import { showStep } from '../../../helpers';
/**
 * Check if there's a start step in the URL and switch to it
 */
export function checkStartStep() {
	if (window.modForm.opts.getVars.startStep && window.modForm.opts.getVars.startStep !== '') {
		const stepId = window.modForm.opts.getVars.startStep;

		if (document.getElementById(stepId)) {
			showStep(stepId);
		}
	}
}
