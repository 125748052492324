import { parseHTMLFromString } from '../../../helpers';

export const abandonmentTriggerCallback = function(e) {
	const mouseY = e.clientY;

	if (window.modForm.isSubmitting || window.modForm.opts.form.classList.contains(window.modForm.opts.formSubmittedCls) || document.body.classList.contains('prevent-abandonment')) {
		document.body.removeEventListener('mouseleave', abandonmentTriggerCallback);
		return false;
	}

	if (mouseY < 50 && document.querySelector('#abandonment:empty')) {
		// Don't show abandonment if form submission has started
		document.body.classList.add('no-scroll');
		// Load Abadonment Popup Content
		const url = 'abandonment/index.html';
		fetch(url).then((response) => response.text()).then(function(htmlString) {
			const parsedHtml = parseHTMLFromString(htmlString);
			document.querySelector('#abandonment').appendChild(parsedHtml);
		});
		document.body.removeEventListener('mouseleave', abandonmentTriggerCallback);
	}
};
