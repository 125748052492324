import { populateInput } from '../../../helpers';
/**
 * Populate form with zip data
 * @param {Object} zipData - zip data
 */
export function populateFormWithZipData(zipData) {
	['city', 'state'].forEach(function(name) {
		if (zipData[name]) {
			populateInput(name, zipData[name]);
		}
	});
	document.querySelectorAll('input[name="zip"], input[name="zip1"]').forEach(function(field) {
		field.value = zipData.zip;
	});
}
