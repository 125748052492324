/**
 * Get current step
 * @returns {Object} - jQuery object of the current step
 */
export function getCurrentStep() {
	let activeStep;
	window.modForm.opts.steps.forEach(function(step) {
		if (step.classList.contains(window.modForm.opts.stepActiveCls)) {
			activeStep = step;
		}
	});
	return activeStep;
}
