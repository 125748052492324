import { maskPhoneFields } from '../../../helpers';
/**
 * Init a one step form simulation for autofilling all inputs
 */
export function initAutofill() {
	const $zip = window.modForm.opts.form.querySelector('input[name="zip"]');

	if ($zip) {
		$zip.addEventListener('focus', function() {
			window.modForm.opts.steps.forEach(function(step) {
				if (step.classList.contains(window.modForm.opts.stepActiveCls)) {
					step.classList.add('autofill');
				}
			});
		});

		$zip.addEventListener('blur', function() {
			window.modForm.opts.steps.forEach(function(step) {
				step.classList.remove('autofill');
			});
			maskPhoneFields(window.modForm.opts.maskedPhoneFields);
		});
	}
}
