/**
 * Handles show/hide HVAC wouldReplace block when HVAC repair value changes
 */
export function initHvacWouldReplaceBlock() {
	// This checks if HVAC repair is Yes,
	// and shows or hides would replace block
	const _check = function() {
		let repair;

		if (window.modForm.opts.repairField.length) {
			window.modForm.opts.repairField.forEach(function(el) {
				if ('radio' === el.type) {
					if (el.checked) {
						repair = el.value.trim();
					}
				} else {
					repair = el.value.trim();
				}
			});
		}

		if (repair) {
			if (window.modForm.opts.hvacRepairFieldValue === repair.toLowerCase()) {
				window.modForm.opts.hvacWouldReplaceBlock[0].style.display = 'block';
			} else {
				window.modForm.opts.hvacWouldReplaceBlock[0].style.display = 'none';
			}
		}
	};

	if (window.modForm.opts.repairField.length && window.modForm.opts.hvacWouldReplaceBlock.length) {
		window.modForm.opts.repairField.forEach(function(el) {
			el.addEventListener('change', function() {
				_check();
			});
		});
		_check();
	}
}
