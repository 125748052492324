import { getCurrentStep, hashStepTracking, showStep } from '../../../helpers';
/**
 * Show prev step
 */
export function showPrevStep() {
	const siblingElement = getCurrentStep().previousElementSibling;

	if (siblingElement && (siblingElement.classList.contains('step') || (siblingElement.hasAttribute('data-bind') && siblingElement.getAttribute('data-bind') === 'step'))) {
		const nextStepId = siblingElement.getAttribute('id');

		if (window.modForm.opts.hashStepTracking) {
			hashStepTracking(nextStepId);
			showStep(nextStepId);
		} else {
			showStep(nextStepId);
		}
	}
}
