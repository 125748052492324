import { generateTooltipMarkup } from './generateTooltipMarkup';
/*
 * Init for all tooltip functionality
 */
export function initTooltips() {
	const tooltipsUrl = window.modUtils.getLandingPage().indexOf('modernize') > 0 ? window.modUtils.getModernizeDomain() + '/quote/resources' : '';

	const request = new XMLHttpRequest();
	let response = {};
	request.open('GET', `${tooltipsUrl}/data/tooltips.json`);
	request.send();
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				const isQSPage = window.modForm.opts.useQSApi;
				const vertical = window.modForm.opts.vertical;
				const tooltips = response[vertical] ? response[vertical] : response.default;
				let formattedVertical;
				if (!response[vertical]) {
					const pathname = window.location.pathname;
					const specialNaming = {
						ALERTS_MEDICAL: 'medical alerts',
						'/quote/bathandshowerremodel': 'bath and shower remodel',
						hvac: 'cooling and heating'
					};

					const tradesAndPaths = Object.keys(specialNaming);

					tradesAndPaths.some(function(element) {
						if (pathname.indexOf(element) > -1) {
							formattedVertical = specialNaming[element];
							return true;
						} else if (element === vertical) {
							formattedVertical = specialNaming[vertical];
							return true;
						}
					});

					if (typeof formattedVertical === 'undefined') {
						formattedVertical = isQSPage ? window.modForm.opts.vertical.replace(/_/g, ' ').toLowerCase() : vertical;
					}

					Object.keys(tooltips).forEach(function(tooltipName) {
						tooltips[tooltipName] = tooltips[tooltipName].replace(/\${trade}/g, formattedVertical);
					});
				}
				const tooltipNames = Object.keys(tooltips);
				const qsFieldNames = {
					FN: 'firstName',
					LN: 'lastName',
					HP: 'homePhone',
					EM: 'email',
					S1: 'address',
					CT: 'city',
					SP: 'state',
					PC: 'zip'
				};
				const $formGroups = Array.from(document.querySelectorAll('.form-group')).filter(function(element) {
					const inputName = element.querySelector('input:not([type="hidden"])')?.name;
					if (tooltipNames.includes(inputName) || tooltipNames.includes(qsFieldNames[inputName])) {
						return element;
					}
				});
				$formGroups.forEach(function(element) {
					const $input = element.querySelector('input:not([type="hidden"])');
					$input.closest('div').classList.add('form-group--tooltip');
					const inputName = $input.name;

					const tooltipMessage = typeof tooltips[inputName] !== 'undefined' ? tooltips[inputName] : tooltips[qsFieldNames[inputName]];
					const toolTipMarkUp = generateTooltipMarkup(inputName, tooltipMessage);
					$input.insertAdjacentElement('afterend', toolTipMarkUp);
				});
			} else {
				console.log('An error has occurred.');
			}
		}
	});
	request.addEventListener('error', function() {
		console.error('Something went wrong');
	});

	const tootTipIcon = document.querySelectorAll('[data-bind="tooltip-icon"]');

	if (tootTipIcon.length) {
		tootTipIcon.forEach(function(toolTip) {
			toolTip.addEventListener('mouseover', window.modUtils.debounce(function(e) {
				const __this = e.target;
				const tooltipName = __this.dataset.tooltipName;
				window.modUtils.heap('track', [
					`${window.modForm.opts.landingPageNoQuery} | Tooltip Open ${tooltipName}`
				]);
				__this.closest('.form-group__tooltip').classList.add('hovered');
			}, 75));

			toolTip.addEventListener('mouseout', window.modUtils.debounce(function(e) {
				const __this = e.target;
				__this.closest('.form-group__tooltip').classList.remove('hovered');
			}, 75));
		});
	}
}
