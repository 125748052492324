/**
 * Get API domain based on whether this is testing or production env
 * @returns {String} QS domain to use
 */
export function getQSApiDomain() {
	let domain = 'https://hsleadpost1.quinstage.com/';
	const env = window.modUtils.getEnv();

	if (env === 'production') {
		domain = 'https://hs.leadpost.net/';
	}

	return domain;
}
