export function getHeapSessionIdCookieData() {
	let heapSessionSubmitCookie = null;
	let heapSessionId = null;
	let initialTimestamp;
	let expireInOneHour;
	let resultsPage = '';

	if (window.modUtils.heapCookie) {
		heapSessionId = window.modUtils.heapCookieData.sessionId;
		heapSessionSubmitCookie = window.modUtils.getCookie(heapSessionId);
	}

	if (heapSessionSubmitCookie !== null) {
		const cookieValue = JSON.parse(decodeURIComponent(heapSessionSubmitCookie));
		initialTimestamp = cookieValue.initialTimestamp;
		expireInOneHour = initialTimestamp + 1 * 60 * 60 * 1000;
		if (window.modForm.opts.isFrontdoorExclusiveMatch) {
			resultsPage = decodeURIComponent(cookieValue.resultsPage);
		} else {
			resultsPage = cookieValue.resultsPage;
		}
	}

	return { heapSessionSubmitCookie, heapSessionId, initialTimestamp, expireInOneHour, resultsPage};
}
