/**
 * Returns specified email provider
 * @param {Object} providers object with array of provider objects
 * @param {String} nameSought - provider name sought
 * @returns {Object} single provider object sought
 */
export function findEmailProvider(providers, nameSought) {
	return (
		providers.data.providers.data.filter(function(provider) {
			return provider.name === nameSought;
		})[0] || []
	);
}
