import { getCurrentStep } from '../../../helpers';
/**
 * Focus on first error of the current step
 */
export function focusOnFirstErrorOnCurrentStep() {
	const $error = getCurrentStep().querySelector('.' + window.modForm.opts.fieldErrorCls);

	if ($error) {
		const $field = $error.querySelector('select, input[type="text"], input[type="tel"], input[type="email"]');

		if ($field && !document.querySelector('.step.left') && !document.querySelector('.step.right')) {
			$field.focus();
		}
	}
}
