import { parseHTMLFromString } from '../../../helpers';
/**
 * Adding new template for loader
 */
export function initFormSubmitLoader() {
	let mainResourcePath = `${window.modUtils.getModernizeDomain()}/quote/resources/shared-resources/`;

	// Get loader from QA on Local Env
	if (mainResourcePath.indexOf('modernize.local') > -1) {
		mainResourcePath = mainResourcePath.replace('modernize.local', 'qa.modernize.com');
		mainResourcePath = mainResourcePath.replace(/https?:/g, window.location.protocol);
	}

	const loaderJS = document.createElement('script');
	loaderJS.src = `${mainResourcePath}scripts/loader/loader.min.js`;

	const loaderCSS = document.createElement('link');
	loaderCSS.href = `${mainResourcePath}styles/components/loader/loader.min.css`;
	loaderCSS.rel = 'stylesheet';

	loaderJS.onload = () => {
		const loaderMarkUp = parseHTMLFromString(Loader.getLoaderHTML()); // eslint-disable-line no-undef
		window.modForm.opts.appendOverlayToElem.append(loaderMarkUp);
		window.modForm.opts.isLoaderLoaded = true;
	};

	document.body.appendChild(loaderJS);
	document.head.appendChild(loaderCSS);
}
