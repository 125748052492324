/**
 * Validate Email
 * @param {String} email - email address to validate
 * @param {Object} $field - jQuery dom input element to validate
 * @returns {Boolean} valid
 */
export function isEmailValid(email) {
	const emailPattern = /^([\w+-]+(?:\.[\w+-]+)*)(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)/i,
		validEmail = emailPattern.test(email);

	return validEmail;
}
