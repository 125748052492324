/* global modForm */
import { getCurrentStep } from '../../helpers';

export function trackFormStepChangeDataLayer() {
	if (!window.dataLayer) {
		return false;
	}

	let stepName = getCurrentStep()?.getAttribute('data-step-name');
	if (modForm.isCurrentStepSecondToLast()) {
		stepName = 'Almost Done';
	}

	if (modForm.isCurrentStepLast()) {
		stepName = 'Last Step';
	}

	window.dataLayer.push({
		event: 'stepChange',
		currentStep: stepName
	});
}
