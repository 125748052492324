import { heapIdentify, getDefaultQuad, transformQuadlink, getQueryParamsFromSessionStorage, defaultQuadLinkValues, getPhoneAssignmentData } from '../../helpers';

/**
 * function to extract the required values from a quad link
 * @param {boolean} useIdlizeQueueInternally if true it will Idlize the ajaxQuadlinkTransform() call inside this function. Useful when calling `initQuadTransform()` immediately in the `initQSForm()`
 */
export function initQuadTransform(useIdlizeQueueInternally = false) {
	// We check if there's a quad link on the query string
	// If not we get the default link
	let hasMarketingParams = false;
	const currentParams = getQueryParamsFromSessionStorage() || window.modUtils.getUrlParamsToObject(),
		hasQuadLink = currentParams.hasOwnProperty('quadlink'), // eslint-disable-line no-prototype-builtins
		hastagID = currentParams.hasOwnProperty('tagID'), // eslint-disable-line no-prototype-builtins
		heapMsg = { message: '' },
		marketingParams = [
			'ad',
			'sp',
			'fb',
			'mt',
			'adposition',
			'dev',
			'gclid',
			'ki',
			'sq',
			'fb_ad_id',
			'fb_adset_id',
			'fb_campaign_id',
			'placement',
			'PartnerSourceID',
			'sub2_id',
			'PublisherSubID'
		],
		hasQsParams = Object.keys(currentParams).some(function(param) {
			return window.modForm.opts.qsParams.includes(param.toUpperCase());
		});

	if (hasQsParams) {
		for (const param in currentParams) {
			if (window.modForm.opts.qsParams.includes(param.toUpperCase()) && typeof currentParams[param] !== 'undefined') {
				window.modForm.opts.quadLinkParams[param.toUpperCase()] = currentParams[param];
			}
		}

		heapIdentify();
		if (window.modForm.opts.phoneAssignmentConfig.showPhoneAssignmentNumber) {
			getPhoneAssignmentData(window.modForm.opts.quadLinkParams);
		}
	} else {
		let quadLinkUrl = '',
			quadLinkType = '';
		if (hastagID) {
			quadLinkUrl = currentParams.tagID;
			quadLinkType = 'tagID';
		} else {
			quadLinkUrl = hasQuadLink ? currentParams.quadlink : getDefaultQuad(window.modForm.opts.vertical);
			quadLinkType = hasQuadLink ? 'quadlink' : 'defaultQuad';
		}

		window.modForm.opts.quadLinkData[quadLinkType] = quadLinkUrl;

		for (const param in currentParams) {
			if (marketingParams.includes(param)) {
				window.modForm.opts.quadLinkData[param] = currentParams[param];
				hasMarketingParams = true;
			}
		}

		if (quadLinkUrl !== '') {
			// update source used for Best Company Blind Match forms (if user is coming in with existing params)
			if (window.modUtils.getLandingPage().includes('bestcompany') && window.modUtils.getLandingPage().includes('find-a-pro')) {
				const quadlinkInput = document.querySelector('input[name="quadlink"]');
				const blindMatchDefaultQuad = getDefaultQuad(defaultQuadLinkValues['bestcompany.com'].findapro);
				const blindMatchQuad = blindMatchDefaultQuad.replace('&default=yes;', '');
				// update quad value being sent through to LeadAnalyzer (so there is no confusion)
				if (quadlinkInput) {
					quadlinkInput.value = blindMatchQuad;
				}
				// transform the quadlink using this blind match specific one
				if (window.modForm.opts.quadLinkData.quadlink) {
					window.modForm.opts.quadLinkData.quadlink = blindMatchQuad;
				} else if (hasMarketingParams) {
					window.modForm.opts.quadLinkData.defaultQuad = blindMatchQuad;
				}
			}

			if (useIdlizeQueueInternally) {
				if ('IdlizeQueue' in window) {
					heapMsg.message = 'initQuadTransform() called on DOMContentLoaded with IdlizeQueue';
					window.IdlizeQueue.pushTask(() => {
						transformQuadlink();
					});
				} else {
					transformQuadlink();
					heapMsg.message = 'initQuadTransform() called on DOMContentLoaded without IdlizeQueue';
				}
			} else {
				transformQuadlink();
				heapMsg.message = 'initQuadTransform() called on window.onload';
			}
		}
		window.modUtils.heap('track', ['Quad Transform', heapMsg]);
	}
}
