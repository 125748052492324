/**
 * Add Fallback Invoca Tags for BestCompany
 */
export function addInvocaFallbackToDataLayer() {
	const intervalId = setInterval(() => {
		if (window.dataLayer) {
			clearInterval(intervalId);

			const matchInput = document.querySelector('input[name="Match"]'),
				vendorKey = matchInput ? matchInput.value : null,
				serviceCode = window.modForm.opts.vertical,
				defaultQuad = window.modForm.getDefaultQuad();

			const tags = [
				{ vendorKey },
				{ serviceCode },
				{ defaultQuad }
			];
			tags.forEach(tag => window.dataLayer.push(tag));
		}
	}, 100);
}
