import { showPrevStep } from '../../../helpers';

export function backButtonClickCallback(e) {
	e.stopImmediatePropagation();
	if (window.modForm.opts.isFormLoading || window.modForm.opts.formSwiperInstance.animating) {
		e.preventDefault();
	} else {
		showPrevStep();
		window.modForm.opts.clickedFormBackButton = true;
	}
}
