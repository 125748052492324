import {
	switchFormLoadingState,
	populateFormWithZipData,
	populateLocationFieldsDynamically
} from '../../../helpers';
import { isZipCodeValid } from '../../../validators';
/**
 * Track first zip change and validate on the go
 * @returns {boolean} - false if no zipField is found
 */
export function initTrackZipChange() {
	if (!window.modForm.opts.zipField.length) {
		return false;
	}

	let isValid = true;
	const isValidating = window.modForm.opts.form.classList.contains(window.modForm.opts.formLoadingCls);

	window.modForm.opts.zipField.forEach(function(zipField) {

		zipField.addEventListener('keydown', function(e) {
			if (e.key === 'Enter' && (isValidating || !isValid)) {
				e.preventDefault();
			}
		});

		zipField.addEventListener('keyup', function() {
			const $input = this;
			const shouldValidateZip = this.value.length === 5 && (!isValidating || !isValid);

			if (shouldValidateZip) {
				switchFormLoadingState(true);

				isZipCodeValid($input.value, function(response) {
					if (response.success) {
						if (response.data) {
							populateFormWithZipData(response.data);
							populateLocationFieldsDynamically(response.data);
						}
						isValid = true;
						$input.closest('.form-group').classList.remove(window.modForm.opts.fieldErrorCls);
						window.modForm.opts.zipCode = response.data.zip;
					} else {
						$input.closest('.form-group').classList.add(window.modForm.opts.fieldErrorCls);
						isValid = false;
					}
					switchFormLoadingState(false);
				});
			}
		});
	});
}
