import { appendFormInput } from '../../../helpers';
/**
 * Add predefined data to the form
 */
export function addRequiredFieldsToContractorForm() {
	const requiredFields = {
		token: '',
		contractorId: '',
		landingPage: window.modUtils.getLandingPage(),
		elqCookieWrite: '0',
		tcpa: 1,
		tcpaText: window.modForm.opts.tcpaCopy && window.modForm.opts.tcpaCopy.length ? Array.from(window.modForm.opts.tcpaCopy).map(tcpa => tcpa.innerText.trim()) : ''
	};

	Object.keys(requiredFields).forEach((key) => {
		appendFormInput(key, requiredFields[key], true);
	});
}
