import { isFreeEmail } from '../validators';

/**
 * Check if the submitted info meets the criteria of a Marketing Qualified Lead (MQL, a lead who is more likely to become a customer than other leads)
 * @param {Object} formData - Form data object
 * @returns {Boolean} - true if meets MQL Criteria
 */
export function meetsMQLCriteria(formData) {
	if (isFreeEmail(formData.email) && formData.websiteUrl === '') {
		return false;
	}

	return true;
}
