import { getFormData } from '../../helpers';
/**
 * Session info will be stored in the form's hidden fields.
 */
export function generateSession() {
	const sessionData = {
			landingpage: window.modForm.opts.landingPageNoQuery,
			thankyou: window.modForm.opts.thankyouPage
		},
		supportedTrackingParams = window.modUtils.getSupportedTrackingParams(),
		formData = getFormData();

	// If any of these params are in the form, add them to sessionData
	Object.keys(formData).forEach(function(key) {
		const nameLC = key.toLowerCase();

		if (supportedTrackingParams.indexOf(nameLC) > -1) {
			sessionData[nameLC] = formData[key];
		}
	});
}
