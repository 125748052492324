/**
 * Custom function to mask Employer Idenfitication Number fields
 * @param {Object} elements - object of fields
 * @returns {boolean} - false if no elements are defined
 */
export function maskEinFields(elements) {
	const allowedKeys = ['Control', 'Tab', 'ArrowLeft', 'ArrowDown', 'ArrowRight', 'ArrowUp', 'Backspace', 'Enter'];

	if (!elements.length) {
		return false;
	}

	elements.forEach(function(element) {
		const nonDigitsRegex = /\D/g;

		element.addEventListener('keydown', function(e) {
			if (element.value.length === 10 && !allowedKeys.includes(e.key)) {
				e.preventDefault();
			}
		});

		element.addEventListener('keyup', function() {
			let value = element.value;
			const mask = /(\d{2})(\d+)/,
				newValues = '$1-$2';
			value = value.replace(nonDigitsRegex, '').substr(0, 9);
			element.value = value.replace(mask, newValues);
		});
	});
}
