/**
 * Check if data meets criteria to match with CXP
 * @param {Object} formData - Form data object
 * @returns {Boolean} - true if meets CXP criteria
 */
export function isSupportedByCxp(formData) {
	const cxpUnsupportedTrades = ['Solar', 'Water Filtration'];

	const isUnsupportedTrade = cxpUnsupportedTrades.indexOf(formData.primaryProjectClass) > -1;
	return !isUnsupportedTrade;
}
