/**
 * Determine if invalid email is the result of optin only
 * @param {Object} response - email validation object containing providers
 * @returns {Boolean} - whether or not a user is eligible to opt in with current address
 */
export function reviewOptinEligibility(response) {
	const providers = response.data.providers;
	const invalidCount = providers.data.filter(function(provider) {
		return !provider.isValid;
	});
	let eligible = false;

	// for future phone optIn: invalidCount[0].name === 'optIn' || invalidCount[0].name === 'DNC'
	if (invalidCount.length === 1 && invalidCount.pop().name === 'optIn') {
		eligible = true;
	}
	return eligible;
}
