/**
 * Check if a company name meets our validation restrictions.
 * @param {String} stringToTest - company name
 * @returns {Boolean} valid
 */
export function isValidCompanyName(stringToTest) {
	const notAcceptedList = ['na', 'n/a'],
		checkInputForAlphabeticCharacter = '.*[a-zA-Z]+.*',
		includesValidCharacters = stringToTest.match(checkInputForAlphabeticCharacter),
		minLength = 1,
		isLongEnough = stringToTest && stringToTest.length >= minLength;

	if (!includesValidCharacters || notAcceptedList.includes(stringToTest.toLowerCase()) || !isLongEnough) {
		return false;
	}
	return true;
}
