export function getProjExUrl(queryString) {
	const passMarketingParams = [
		'token',
		'src',
		'sub_id',
		'sub2_id',
		'pub_id',
		'account',
		'campaign',
		'ad_group',
		'keyword',
		'campaignid',
		'adgroupid',
		'agid',
		'keywordid',
		'loc1',
		'loc2',
		'kw',
		'gclid',
		'projectid',
		'userid',
		'quadlink',
		'CLK',
		'CCID',
		'QTR',
		'DCK',
		'utm_source',
		'utm_medium',
		'utm_campaign',
		'utm_content',
		'utm_term'
	];
	const getVars = window.modUtils.getUrlParamsToObjectLC(queryString),
		marketingParams = {};
	let url;

	if (window.projectId) {
		url = window.modUtils.getModernizeDomain() + '/my/project-' + window.projectId;

		for (let i = 0; i < passMarketingParams.length; i++) {
			const passMarketingParam = passMarketingParams[i];
			const passMarketingParamLC = passMarketingParam.toLowerCase();

			if ('undefined' !== typeof getVars[passMarketingParamLC]) {
				marketingParams[passMarketingParam] = getVars[passMarketingParamLC];
			}
		}

		if (Object.keys(getVars).length && getVars.hrcreferrer) {
			marketingParams.referrer = getVars.hrcreferrer;
		} else {
			marketingParams.referrer = window.location.protocol + '//' + window.location.host + window.location.pathname;
		}

		if (Object.keys(marketingParams).length) {
			url += '?' + new URLSearchParams(marketingParams).toString();
		}
	} else {
		url = 'https://modernize.com/quote/unmatched';
	}

	return url;
}
