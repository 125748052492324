import {
	getCurrentStep,
	focusOnFirstErrorOnCurrentStep,
	switchFormLoadingState,
	getFormData,
	preprocessContractorFormData,
	submitForm,
	trackInvalidFieldsInHeap,
	markFieldAsInvalid
} from '../../../helpers';

import { isFormValid, isZipCodeValid } from '../../../validators';
/**
 * Handles form (step) submission & validations
 */
export function initContractorFormSubmit() {
	window.modForm.opts.form.addEventListener('submit', function(e) {
		e.preventDefault();

		const $activeStep = getCurrentStep(),
			currentStep = Array.from(window.modForm.opts.steps).indexOf(getCurrentStep()),
			$stepZipField = $activeStep.querySelector('input[name="companyZip"]');
		let formData;

		// Validate step fields
		if (!isFormValid($activeStep)) {
			focusOnFirstErrorOnCurrentStep();
			return;
		}

		// Validate zip if step contains it
		if ($stepZipField && !window.modForm.isZipValid) {
			switchFormLoadingState(true);
			isZipCodeValid($stepZipField.value, function(response) {
				switchFormLoadingState(false);

				// If it was succesful submit step
				if (response.success) {
					formData = getFormData();

					formData = preprocessContractorFormData(formData, currentStep);

					submitForm(formData);
				} else {
					trackInvalidFieldsInHeap('Zip');
					markFieldAsInvalid($stepZipField);
					focusOnFirstErrorOnCurrentStep();
				}
			});
		} else {
			// If no zip input found, just submit step normally
			formData = getFormData();

			// Preprocess it
			formData = preprocessContractorFormData(formData, currentStep);

			submitForm(formData);
		}
	});
}
