import { getCurrentStep } from '../../../helpers';
/**
 * Disable button if current step has a data attribute of infield
 */
export function handleNextButton() {
	const $activeStep = getCurrentStep(),
		$nextBtn = $activeStep.querySelector('.btn-primary'),
		$activeStepData = $activeStep.dataset;

	if ('infield' in $activeStepData) {
		const name = $activeStepData.infield,
			shouldButtonBeEnabled
				= (name === 'zip' && window.modForm.isZipValid)
				|| (name === 'phone-email' && window.modForm.isPhoneValid && window.modForm.isEmailAddressValid)
				|| (name === 'email' && window.modForm.isEmailAddressValid)
				|| (name === 'phone' && window.modForm.isPhoneValid);

		if (!shouldButtonBeEnabled) {
			$nextBtn.setAttribute('disabled', true);
		} else {
			$nextBtn.removeAttribute('disabled');
		}
	} else {
		if (!document.querySelector('.step.left') && !document.querySelector('.step.right')) {
			$nextBtn.removeAttribute('disabled');
		}
	}
}
