// Adds parameter to HRC URL to suppress the conversion pixels
export function initSuppressConversionPixel(formData) {
	const lookUp = {
		NumberOfWindows: '1',
		windowsNumber: '1 window'
	};

	if (!formData && Object.keys(formData).length === 0) {
		return;
	}

	if ('projectData' in formData) {
		formData = formData.projectData;
	}

	Object.keys(formData).forEach((key) => {
		if (formData[key] && lookUp[key] && formData[key] === lookUp[key]) {
			window.modForm.opts.shouldSuppressConversionPixel = true;
		}
	});
}
