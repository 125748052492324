import { populateInput } from '../../../helpers';
/**
 * If input exists, populate it; if not - create hidden
 * @param {String} name - name of a field
 * @param {String} value - value of a field
 * @param {Bool} noPopulate - set to true if you only want to create input if it doesn't exist, and don't want to update existing input
 */
export function appendFormInput(name, value, noPopulate) {
	if (!window.modForm.opts.form.querySelector('input[name="' + name + '"]')) {
		const input = document.createElement('input');
		input.type = 'hidden';
		input.id = name;
		input.name = name;
		input.value = value;
		window.modForm.opts.form.appendChild(input);
	} else if (!noPopulate) {
		populateInput(name, value);
	}
}
