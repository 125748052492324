import { getCurrentStep, showStep } from '../../../helpers';
/**
 * Adds event listener for hash navigation
 */
export function watchHashChange() {
	window.onhashchange = function(e) {
		e.preventDefault();

		let oldURL, newURL;

		if (window.modForm.opts.useStepNameHash) {
			oldURL = typeof e.oldURL !== 'undefined' ? e.oldURL.split('#')[1] || '1' : '1';
			newURL = typeof e.newURL !== 'undefined' ? e.newURL.split('#')[1] || '1' : '1';

			const oldStep = document.querySelector(`[data-step-name="${oldURL}"]`);
			const newStep = document.querySelector(`[data-step-name="${newURL}"]`);

			if (newStep) {
				newURL = newStep.id.slice(4);
			}

			if (oldStep) {
				oldURL = oldStep.id.slice(4);
			}
		} else {
			oldURL = typeof e.oldURL !== 'undefined' ? e.oldURL.split('#q')[1] || '1' : '1';
			newURL = typeof e.newURL !== 'undefined' ? e.newURL.split('#q')[1] || '1' : '1';
		}

		// Makes the browser forward / backward move the form around to match the stepNameHash
		if (oldURL !== newURL) {
			const currentStep = getCurrentStep();
			let nextStepId;

			if (oldURL > newURL && currentStep.previousElementSibling) {
				nextStepId = currentStep.previousElementSibling.getAttribute('id');
			} else if (oldURL < newURL && currentStep.nextElementSibling) {
				nextStepId = currentStep.nextElementSibling.getAttribute('id');
			}

			if (nextStepId) {
				showStep(nextStepId);
			}
		}
		oldURL = newURL;
	};
}
