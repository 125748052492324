import { getQSApiDomain } from '../../helpers';

export function getPhoneNumberType(phoneNumber) {
	let phoneType = 'Wireless',
		response = {};
	const request = new XMLHttpRequest(),
		apiUrl = `${getQSApiDomain()}${window.modForm.opts.isWirelesssApi}/${phoneNumber}/get`;
	request.open('GET', apiUrl);
	request.send();
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				if (response.status === 'Success' && response.Result === 'false') {
					phoneType = 'NonWireless';
				}
			}
		}
	});
	request.addEventListener('error', function() {
		console.error('Something went wrong');
	});

	return phoneType;
}
