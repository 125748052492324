/**
* Adding vwo-test parameter value (HSMERCH-XXXX) to the body
* Adding vwo-test parameter value (HSMERCH-XXXX) with version to the sections for multi-variate test
*/
export function addVWOTestQueryParam() {
	const vwoTestParam = Object.keys(window.modForm.opts.getVars).filter(param => param.startsWith('vwo-test'));
	const vwoTestParamValue = window.modForm.opts.getVars[vwoTestParam];

	if (!(vwoTestParam && vwoTestParamValue)) {
		return;
	}

	const testVersions = Object.keys(window.modForm.opts.getVars).filter(param => param.match(/v[1-9]{1,}/g));

	// Multivariate Test
	if (testVersions.length > 0) {
		testVersions.forEach(version => {
			const classLists = window.modForm.opts.getVars[version];
			const elementAttributeVal = `${vwoTestParamValue}-${version}`;

			if (classLists) {
				classLists.split(',').forEach(className => {
					const element = document.getElementsByClassName(className)[0];

					if (element) {
						element.setAttribute(window.modForm.opts.vwoVariationDataAttribute, elementAttributeVal);
					}
				});
			}
		});
	} else {
		setTimeout(() => {
			document.body.setAttribute(window.modForm.opts.vwoVariationDataAttribute, vwoTestParamValue);
		}, 500);
	}
}
