/**
 * Init click to proceed for radio buttons in the form
 */
export function initClickToProceed() {
	const $form = window.modForm.opts.form;

	$form.querySelectorAll('input[type="radio"]').forEach(function($radio) {
		$radio.addEventListener('click', function() {
			if (window.modForm.radioButtonClickedByMouse) {
				if (!window.modForm.opts.formSwiperInstance.animating) {
					window.modForm.showNextStep();
				}
				window.modForm.radioButtonClickedByMouse = false;
			}
		});
	});
}
