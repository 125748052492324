/**
 * Wire all form elements based on selectors
 */
export function initFormElements() {
	window.modForm.opts.formElemsSelectors = {...window.modForm.opts.formElemsSelectorsDefaults, ...window.modForm.opts.formElemsSelectors};
	Object.keys(window.modForm.opts.formElemsSelectors).forEach((key) => {
		if (!window.modForm.opts[key]) {
			window.modForm.opts[key] = window.modForm.opts.form.querySelectorAll(window.modForm.opts.formElemsSelectors[key]);
		}
	});
}
