export function getTcpaElementContent($element) {
	if ($element) {
		if ($element.innerText) {
			return $element.innerText;
		} else if ($element.textContent) {
			return $element.textContent;
		} else if ($element.innerHTML) {
			return $element.innerHTML;
		}
	}
	return '';
}
