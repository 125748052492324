export const getFormFieldNameFromMappings = function(fieldName) {
	switch (fieldName) {
	case 'PC':
		return 'zip';
	case 'FN':
		return 'firstName';
	case 'LN':
		return 'lastName';
	case 'S1':
		return 'address';
	case 'SP':
		return 'state';
	case 'CN':
		return 'country';
	case 'EM':
		return 'email';
	case 'HP':
		return 'homePhone';
	case 'CentralHeatingInstallType':
	case 'CentralHeatingRepairType':
	case 'BoilerSystemInstallType':
	case 'BoilerSystemRepairType':
		return 'BoilerHeatingInstallRepairType';
	case 'PortalServiceCode':
		return 'service';
	case 'BathwrapProjectType':
		return 'BathProjectScope';
	default:
		return fieldName;
	}
};
